import React from 'react'
import $ from 'jquery';

export default class DownloadForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      g_recaptcha_response: 'klmkwelkm2-mk2ml2389u4nkfejhijfrw0',
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      message: ''
    }
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  handleDownloadChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDownloadSubmit = e => {
    $.ajax({
      url: "https://71jmri3wtj.execute-api.us-west-2.amazonaws.com/production/",
      type: "POST",
      dataType: "JSON",
      data: JSON.stringify({
        ReplyTo: this.state.email,
        Name: this.state.firstName + " " + this.state.lastName,
        Company: this.state.company,
        Comments: window.location.pathname,
        Subject: "New submission from Resource Download",
        Gotcha: this.state.verifyAddress,
        GoogleRecaptchaResponse: this.state.g_recaptcha_response
      }),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        window.location.href = "thank-you";
      },
      error: function (jqXhr, textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
    e.preventDefault();
  }

  verifyCallback = response => {
    this.setState({ g_recaptcha_response: response });
  };

  render() {
    return (
      <div className="container">
        <div className="download">
          <h1 className="thin">Download Now!</h1>
          <form
            name="download"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleDownloadSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="download" />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={this.handleDownloadChange} />
              </label>
            </div>
            <div className="field">
              <label className="label" htmlFor={'name'}>
                First Name *
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'text'}
                  name={'firstName'}
                  onChange={this.handleDownloadChange}
                  id={'firstName'}
                  required={true}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'name'}>
                Last Name *
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'text'}
                  name={'lastName'}
                  onChange={this.handleDownloadChange}
                  id={'lastName'}
                  required={true}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'email'}>
                Email *
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'email'}
                  name={'email'}
                  onChange={this.handleDownloadChange}
                  id={'email'}
                  required={true}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'name'}>
                Company Name *
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'text'}
                  name={'company'}
                  onChange={this.handleDownloadChange}
                  id={'company'}
                  required={true}
                />
              </div>
            </div>
            <div className="field custom-button">
              <button type="submit">
                Download Now!
              </button>
            </div>
          </form>
        </div>
      </div>

    )
  }
}

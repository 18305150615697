import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import DownloadForm from '../components/DownloadForm'

export const ResourcesPostTemplate = ({
  content,
  contentComponent,
  description,
  downloadTitle,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section" style={{ marginTop: 100 }}>
      <div className="container ">
        <div className="columns">
          <div className="column is-7">
            <PostContent content={content} className="markdown" />
          </div>
          <div className="column is-5">
            <DownloadForm downloadTitle={downloadTitle} />
          </div>
        </div>
      </div>
    </section>
  )
}

ResourcesPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const ResourcesPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ResourcesPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        downloadTitle={post.frontmatter.downloadTitle}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

ResourcesPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ResourcesPost

export const pageQuery = graphql`
  query ResourcesPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        downloadTitle
      }
    }
  }
`
